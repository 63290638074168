import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import GenericForm from '../../../components/base/v2/genericForm';
import FlowUpsertForm from '../../../sections/flow/form';
import useBlocks from '../../../hooks/api/blocks';
import { handleFormDataToModel } from '../../rulesFlow/utils';
import { emptyFlowObject } from '../../../providers/rules_flow/utils';

import * as I from '../../../interfaces/blocks';
import * as I2 from '../../../interfaces/flow';
import { RetentionModel } from '../../../interfaces/retentionFlow';
import { formatConstants } from '../../../utils/formatConstants';
import { constants } from '../../../constants';

const BlocksInfo = () => {
  const [initialValues, setInitialValues] =
    useState<I.BlocksModel>(emptyFlowObject);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { updateBlock, getOneBlock } = useBlocks();
  const { id } = useParams<{ id: string }>();

  const handleEditing = () => {
    setIsEditing(!isEditing);
  };

  const loadInfo = async () => {
    try {
      setIsLoading(true);
      const { data } = await getOneBlock({ id });
      setInitialValues(data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (
    data: I.BlocksModel | I2.FlowModel | RetentionModel
  ) => {
    try {
      const res = await updateBlock({
        data: handleFormDataToModel(data as I.BlocksModel),
        id,
        version: initialValues.version,
      });
      if ([200, 201, 204].includes(res.request.status)) {
        handleEditing();
        loadInfo();
        const message = formatConstants(constants.updatedWithSuccess, 'Bloco');
        toast.success(message);
        return;
      }
      const { reason } = JSON.parse(res.request.response);
      const message = formatConstants(constants.errorToSubmitContent, '');
      toast.error(`${message}: ${reason}`);
    } catch {
      const message = formatConstants(constants.errorToSubmitContent, '');
      toast.error(message);
    }
  };

  useEffect(() => {
    loadInfo();
  }, []);

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Bloco', href: '/blocks' },
        { label: initialValues?.description },
      ]}
      title={initialValues?.description}
      isDisabled={!isEditing}
      handleAction={handleEditing}
      actionText="Editar Bloco"
      backButtonUrl="/blocks"
      showBackButton
    >
      <FlowUpsertForm
        flow={initialValues}
        isLoaded={!isLoading}
        handleSubmit={handleSubmit}
        isEditing={isEditing}
        handleEditing={handleEditing}
        isBlock
      />
    </GenericForm>
  );
};

export default BlocksInfo;
