export const ErrorPolicyTranslations: { [key: string]: string } = {
  ignore: 'Ignorar',
  'fail-if-needed': 'Fail-if-needed',
  fail: 'Falhar',
};

export const flowPolicyError = [
  { option: 'Ignorar', value: 'ignore' },
  { option: 'Fail-if-needed', value: 'fail-if-needed' },
  { option: 'Falhar', value: 'fail' },
];

export const retentionPolicyError = [
  { option: 'Ignorar', value: 'ignore' },
  { option: 'Falhar', value: 'fail' },
];
