import { atomWithReset, useResetAtom } from 'jotai/utils';
import { NodeModel } from '../../../interfaces/flow';

export const clonedNodesAtom = atomWithReset<NodeModel[]>([]);

export const ResetAtom = () => {
  const resetClonedNodesAtom = useResetAtom(clonedNodesAtom);

  return {
    resetClonedNodesAtom,
  };
};
