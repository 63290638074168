import fileFoldedIcon from '../../../../assets/icons/fileFoldedIcon.svg';
import shopIcon from '../../../../assets/icons/shopIcon.svg';
import teamIcon from '../../../../assets/icons/teamIcon.svg';
import handshakeIcon from '../../../../assets/icons/handshakeIcon.svg';
import fileUnfoldedIcon from '../../../../assets/icons/fileUnfoldedIcon.svg';
import bookIcon from '../../../../assets/icons/bookIcon.svg';
import adjustmentIcon from '../../../../assets/icons/adjustmentIcon.svg';
import menuAppIcon from '../../../../assets/icons/menuAppIcon.svg';
import eyeOpenIcon from '../../../../assets/icons/eyeOpenIcon.svg';
import TagIcon from '../../../../assets/icons/tagIcon.svg';

export const OrderOptions = [
  { name: 'Todos Pedidos', icon: eyeOpenIcon, link: '/', exact: true },
  { name: 'Fila', icon: eyeOpenIcon, link: '/filas' },
  { name: 'Finalizados', icon: eyeOpenIcon, link: '/orders' },
  {
    name: 'Suspensos',
    icon: eyeOpenIcon,
    link: '/suspensions',
    role: 'resolve_suspension',
  },
];

export const RuleOptions = [
  { name: 'Fluxos', icon: adjustmentIcon, link: '/rules-flow' },
  { name: 'Retenção', icon: adjustmentIcon, link: '/retention-flow' },
  { name: 'Blocos', icon: menuAppIcon, link: '/blocks' },
];

export const sidebarOptions = [
  {
    name: 'Gestão de filas',
    icon: fileFoldedIcon,
    link: '/gestao-de-filas',
    role: 'view_queues',
  },
  { name: 'Clientes', icon: shopIcon, link: '/client', role: 'view_clients' },
  { name: 'Grupos', icon: teamIcon, link: '/groups', role: 'view_groups' },
  {
    name: 'Parceiros',
    icon: handshakeIcon,
    link: '/partners',
    role: 'view_partners',
  },
  {
    name: 'Listas',
    icon: fileUnfoldedIcon,
    link: '/actions-list',
    role: 'view_actions',
  },
  { name: 'Modelos', icon: bookIcon, link: '/models', role: 'view_models' },
  { name: 'Tags', icon: TagIcon, link: '/tags', role: 'view_tags' },
];

export const isDropdownActive = (pagePath: string | null, title: string) => {
  if (!pagePath) return false;
  switch (title) {
    case 'Pedidos':
      return ['/filas', '/orders', '/suspensions', '/'].includes(pagePath);
    case 'Regras':
      return ['/rules-flow', '/blocks', '/retention-flow'].includes(pagePath);
    default:
      return false;
  }
};
