import React, {
  useContext,
  createContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import useRulesFlows from '../../hooks/api/rules_flows';
import { emptyFlowObject, emptyRulesDataObject } from './utils';

import * as I from './interfaces';
import * as I2 from '../../interfaces/flow';

const RulesFlowContext = createContext<I.Context>({} as I.Context);

const RulesFlowProvider = ({
  children,
}: I.ProviderProps): React.ReactElement => {
  const [flowData, setFlowData] = useState<I2.FlowModel>(emptyFlowObject);
  const [rulesData, setRulesData] =
    useState<I.RulesDataModel>(emptyRulesDataObject);
  const [hasAction, setHasAction] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFlowLoaded, setIsFlowLoaded] = useState(false);
  const [isRulesLoaded, setIsRulesLoaded] = useState(false);

  const { keycloak } = useKeycloak();
  const { authenticated } = keycloak;

  const { getOneFlow, getRules } = useRulesFlows();
  const handleHasAction = (): void => {
    setHasAction((oldValues) => !oldValues);
  };

  const location = useLocation();
  const splitedPath = location.pathname.split('/');
  const id = splitedPath[splitedPath.length - 1];

  const handleGetFlows = async (): Promise<void> => {
    try {
      if (splitedPath.includes('rules-flow')) {
        setIsFlowLoaded(false);
        if (id) {
          const response = await getOneFlow(id);
          setFlowData(response.data);
        }
        return;
      }
    } finally {
      setIsFlowLoaded(true);
    }
  };

  const handleGetRules = async (): Promise<void> => {
    try {
      setIsRulesLoaded(false);
      const response = await getRules();
      setRulesData({
        rules: response.data.items,
        rulesNames: response.data.items.map((rule: I2.RuleModel) => ({
          label: rule.name,
          value: rule.name,
        })),
      });
      return;
    } finally {
      setIsRulesLoaded(true);
    }
  };

  const handleDisabled = async (): Promise<void> => {
    setIsDisabled((old) => !old);
  };

  useEffect(() => {
    const loadInfo = async (): Promise<void> => {
      const hasFlowOrBlock =
        splitedPath.includes('rules-flow') || splitedPath.includes('blocks');

      if (authenticated && hasFlowOrBlock && splitedPath.includes('info')) {
        try {
          await Promise.all([handleGetFlows(), handleGetRules()]);
        } catch {
          toast.error('Erro ao carregar as informações');
        }
      }
    };

    loadInfo();
  }, [id, authenticated]);

  useEffect(() => {
    setIsLoaded(Boolean(isFlowLoaded && isRulesLoaded && authenticated));
  }, [isFlowLoaded, isRulesLoaded, authenticated]);

  return (
    <RulesFlowContext.Provider
      value={useMemo(
        () => ({
          dataContext: flowData,
          isLoaded,
          rulesData,
          hasID: id,
          hasAction,
          handleHasAction,
          handleDisabled,
          getNewVersion: handleGetFlows,
          isDisabled,
        }),
        [isLoaded, hasAction, isDisabled]
      )}
    >
      {children}
    </RulesFlowContext.Provider>
  );
};

function useRulesFlow(): I.Context {
  const context = useContext(RulesFlowContext);
  const {
    dataContext,
    isLoaded,
    isDisabled,
    rulesData,
    hasID,
    hasAction,
    handleHasAction,
    handleDisabled,
    getNewVersion,
  } = context;

  return {
    dataContext,
    isLoaded,
    isDisabled,
    rulesData,
    hasID,
    hasAction,
    handleHasAction,
    handleDisabled,
    getNewVersion,
  };
}

export { RulesFlowProvider, useRulesFlow };
