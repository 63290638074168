import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { Alert, Icon, Stack } from '@chakra-ui/react';
import { HiArrowRight } from 'react-icons/hi';
import { FiAlertTriangle } from 'react-icons/fi';

import * as I from './interfaces';

import Button from '../../../base/v2/button';

export default function GenericFooter({
  onOpen,
  onDescartOpen,
  alertText,
  publishNewText,
  publishUpdateText,
  isEditing,
  hasID,
}: I.FooterProps): React.ReactElement {
  const history = useHistory();

  const {
    formState: { isSubmitting, isDirty },
    clearErrors,
  } = useFormContext();

  const handleCancelButtonClick = (): void => {
    if (hasID) {
      onDescartOpen();
    } else {
      history.go(-1);
    }
  };

  const cancelText = hasID ? 'Descartar alterações' : 'Cancelar';
  const publishText = hasID ? publishUpdateText : publishNewText;

  return (
    <>
      {isEditing && (
        <Stack
          direction="row"
          spacing="medium"
          background="white"
          my="medium"
          p="medium"
          borderRadius="extra-large"
          justifyContent="space-between"
        >
          {isDirty && (
            <Alert
              status="warning"
              width="fit-content"
              height="bigger-plus"
              variant="subtle"
              borderRadius="larger"
              border="1px solid"
              borderColor="warning.50"
              backgroundColor="warning.20"
            >
              <Icon
                as={FiAlertTriangle}
                marginRight="small"
                color="warning.100"
              />
              {alertText}
            </Alert>
          )}

          <Stack />
          <Stack direction="row" spacing="regular">
            <Button
              isDisabled={hasID && !isDirty}
              onClick={handleCancelButtonClick}
              isLoading={isSubmitting}
            >
              {cancelText}
            </Button>

            <Button
              isDisabled={!isDirty}
              colorScheme="green"
              rightIcon={<Icon as={HiArrowRight} />}
              isLoading={isSubmitting}
              onClick={() => {
                clearErrors();
                onOpen();
              }}
            >
              {publishText}
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
}
