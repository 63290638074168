import React, { useEffect } from 'react';
import {
  IdColumn,
  descriptionAndIDColumn,
  createdAtColumn,
  modifiedAtColumn,
} from '../../components/base/v2/table/columns';

import GenericListPage from '../genericList';
import useDocumentTitle from '../../hooks/documentTitle';
import { cleanFilters } from '../../components/base/v2/table/stores';
import useRulesFlows from '../../hooks/api/rules_flows';
import { FlowModel } from '../../interfaces/flow';

const RulesFlowList: React.FC = () => {
  useDocumentTitle('Trex - Fluxos');
  const { getFlows } = useRulesFlows();
  const { resetsearchText, resetfilterBy, resetfilters } = cleanFilters();

  useEffect(() => {
    resetsearchText();
    resetfilterBy();
    resetfilters();
  }, []);

  const rulesFlowColumns = [
    descriptionAndIDColumn('/rules-flow/info'),
    IdColumn,
    createdAtColumn,
    modifiedAtColumn,
  ];

  return (
    <GenericListPage<FlowModel>
      actionButton={{
        pushPath: '/rules-flow/create',
        text: 'Criar novo fluxo',
      }}
      breadcrumb={{
        items: [{ label: 'Início', href: '/' }, { label: 'Fluxos de regras' }],
      }}
      title="Fluxos de regras"
      table={{
        fetch: {
          apiCall: getFlows,
          title: 'regras',
        },
        body: {
          columns: rulesFlowColumns,
          searchLabel: 'Busque por nome, ID, regra ou nó',
          accessURL: '/rules-flow/info',
          isSearch: false,
        },
      }}
    />
  );
};

export default RulesFlowList;
