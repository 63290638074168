import { FlowCommentsFormModel } from '../../components/ordersInfo/tabInfo/commentsInfo/interface';
import { FlowModel } from '../../interfaces/flow';

export const handleFormDataToModel = (values: FlowModel) => {
  values.allow_review = JSON.parse(`${values.allow_review}`);
  values.nodes.forEach((node) => {
    if (!node.relay_to) delete node.relay_to;

    node.terms.forEach((term) => {
      term.complement = JSON.parse(`${term.complement}`);
    });
  });

  return values;
};

export const handleCommentsFormToModel = (
  data: FlowCommentsFormModel,
  flow: FlowModel
) => {
  flow.comment = {
    title: data.title,
    description: data.message,
  };

  return flow;
};
