import { extendTheme } from '@chakra-ui/react';
import SharonSans from '../assets/fonts/sharonsans/SharonSans-Regular.woff2';

const activeLabelStyles = {
  lineHeight: '1rem',
  transform: 'translateY(-0.9rem) scale(0.9)',
  background: 'white',
  color: 'neutral.400',
  px: 1,
};

export const theme = extendTheme({
  breakpoints: {},

  colors: {
    white: {
      '50': '#FCFCFC',
      '100': '#f2f2f2',
      '200': '#e5e5ea',
      '300': '#d1d1d6',
      '400': '#c7c7cc',
      '500': '#aeaeb2',
      '600': '#8e8e93',
      '700': '#fff',
      '800': '#e8ebf9',
      '900': '#f8f9ff',
    },

    black: {
      '100': '#000',
      '200': '#120000',
    },

    dark: {
      '100': '#8e8e93',
      '200': '#636366',
      '300': '#48484a',
      '400': '#3a3a3c',
      '500': '#2c2c2e',
      '600': '#1c1c1e',
      '700': '#131313',
      '800': '#303030',
    },

    red: {
      '400': '#f7adae',
      '500': '#f05d5e',
      '700': '#d91924',
      '800': '#ee0000',
    },

    gray: {
      '400': '#f5f5f5',
      '500': '#404e4d',
      '600': '#a3a8ad',
      '700': '#aaa',
      '800': '#ddd',
      '900': '#c1c1c1',
    },

    green: {
      '400': '#a9c0ab',
      '500': '#5b8c5a',
    },

    yellow: {
      '400': '#ffedab',
      '500': '#f2a359',
    },

    blue: {
      '100': '#b1bbeb',
      '200': '#f3f5ff',
      '300': '#e8ecff',
      '400': '#4e598c',
      '500': '#191d32',
      '600': '#0A1133',
      '900': '#191d32',
    },

    // new DS
    v2: {
      white: {
        '100': '#FFF',
        '200': '#f2f2f3',
        '300': '#EDEFF1',
        '400': '#A8A8AD',
      },

      black: {
        '100': '#000',
        '200': '#181818',
      },

      gray: {
        '50': '#F7F7FA',
        '100': '#F2F4F7',
        '200': '#303742',
        '400': '#F5F5F5',
      },

      dark: {
        '100': '#D0D5DD',
        '200': '#767F8D',
      },

      red: {
        '100': '#FEECEC',
        '200': '#E6171E',
        '300': '#eba3ae',
        '400': '#F73E41',
      },

      green: {
        '50': '#e6f7f0',
        '100': '#E5F6F0',
        '200': '#00A868',
        '300': '#008E5A',
        '400': '#032E1B',
        '500': '#9db6ab',
        '600': '#42ec9a',
      },

      greenAlpha: {
        '100': '#00a8681a',
      },

      orange: {
        '100': '#FEF3E9',
        '200': '#D95E00',
        '300': '#ecc49b',
        '400': '#f38428',
        '700': '#ff8c00',
      },

      blue: {
        '100': '#E9F2FE',
        '200': '#0057D9',
        '300': '#002F68',
      },

      yellow: {
        '100': '#FBD38D55',
      },
    },

    primary: {
      '500': '#00A868',
      '600': '#008E5A',
      '700': '#008E5A',
    },

    neutral: {
      '50': '#e6e9ed',
      '100': '#f2f4f7',
      '200': '#e9ecf0',
      '300': '#d0d5dd',
      '400': '#a6aeba',
      '500': '#767f8d',
      '600': '#4c5462',
      '700': '#303742',
      '800': '#222730',
      '900': '#1c2129',
    },

    birdPersonBlack: {
      '500': '#4C5462',
      '600': '#303742',
      '700': '#222730',
      '800': '#181818',
    },

    squanchyGray: {
      '0': '#FFFFFF',
      '20': '#F2F4F7',
      '50': '#EDEFF1',
      '100': '#E9ECF0',
      '200': '#D0D5DD',
      '300': '#A6AEBA',
      '400': '#6D7583',
      '900': '#6D7583',
    },

    info: {
      '20': '#E9F2FE',
      '50': '#D4E5FD',
      '100': '#287df3',
      '200': '#0057d9',
      '800': '#002F68',
    },
    warning: {
      '20': ' #FEF3E9',
      '50': '#FDE6D4',
      '100': '#f38428',
      '200': '#d95e00',
      '300': '#FFCC80',
      '500': '#f38428',
      '800': '#713F00',
    },
    danger: {
      '20': ' #FEECEC',
      '50': '#FDD8D9',
      '100': '#F73E41',
      '200': '#e6171e',
      '800': '#680003',
    },
    success: {
      '20': ' #E5F6F0',
      '50': '#CCEEE1',
      '100': '#00A868',
      '200': '#008E5A',
      '800': '#032E1B',
    },
    secundary: {
      '20': ' #F5F2FE',
      '50': '#b898fd',
      '100': '#9a79f3',
      '200': '#7757d9',
      '800': '#1D1647',
    },
    disable: {
      '20': ' #F2F4F7',
      '50': '#CDCDD0',
      '200': '#D0D5DD',
      '300': '#A6AEBA',
    },

    base: {
      mortyYellow: {
        '20': '#FFF6D9',
        '50': '#F8CC43',
        '100': '#F3B700',
        '200': '#D9A600',
        '800': '#B38900',
      },
      summerOrange: {
        '20': '#FFF9F6',
        '50': '#F5AC7D',
        '100': '#DD6B00',
        '200': '#AD4F00',
        '800': '#713F00',
      },
      firefoxRed: {
        '20': '#FFEDE9',
        '50': '#FF897A',
        '100': '#E91C20',
        '200': '#C0000C',
        '800': '#680003',
      },
      bethPink: {
        '20': '#FBE5EC',
        '50': '#F55D8B',
        '100': '#DF285F',
        '200': '#A71A44',
        '800': '#732640',
      },
      alienPurple: {
        '20': '#F6EDFF',
        '50': '#B59BFF',
        '100': '#805FE3',
        '200': '#6744C9',
        '800': '#360097',
      },
      mrMeeseeksBlue: {
        '20': '#EBF0FF',
        '50': '#7AABFF',
        '100': '#0574E9',
        '200': '#005BBE',
        '800': '#002F68',
      },
      trampolinBlue: {
        '20': '#A3CEFF',
        '50': '#509CF2',
        '100': '#2B7DD9',
        '200': '#2467B3',
        '800': '#1E5594',
      },
      dolphinTurquoise: {
        '20': '#9CE4ED',
        '50': '#4ECDDE',
        '100': '#1695A6',
        '200': '#0F6773',
        '800': '#0C5059',
      },
      forestGreen: {
        '20': '#BCEBD7',
        '50': '#4ABA89',
        '100': '#1C8C5C',
        '200': '#156644',
        '800': '#104D33',
      },
      caipirinhaLime: {
        '20': '#EBF7DF',
        '50': '#90C458',
        '100': '#63992E',
        '200': '#4B7322',
        '800': '#3A591A',
      },
    },
  },

  fonts: {
    body: SharonSans,
    heading: SharonSans,
    mono: SharonSans,
  },

  fontSizes: {
    xxs: '0.75rem',
    xs: '0.8125rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.25rem',
    lgg: '1.5rem',
    xl: '1.875rem',
    xxl: '2.25rem',
    // new DS
    small: '0.75rem',
    regular: '0.875rem',
    medium: '1rem',
    large: '1.125rem',
    larger: '1.25rem',
    hug: '1.375rem',
    'extra-large': '1.5rem',
    big: '3rem',
  },

  sizes: {
    zero: '0px',
    tiniest: '0.063rem',
    tinier: '0.125rem',
    tiny: '0.25rem',
    smallest: '0.5rem',
    smaller: '0.625rem',
    small: '0.75rem',
    regular: '0.875rem',
    medium: '1rem',
    large: '1.125rem',
    larger: '1.25rem',
    'extra-large': '1.5rem',
    big: '2rem',
    bigger: '2.5rem',
    'bigger-plus': '2.75rem',
    'extra-big': '3rem',
    huge: '3.5rem',
    huger: '4rem',
    'extra-huge': '4.5rem',
    hugest: '6.25rem',
    colossal: '8rem',
    full: '100%',
  },

  shadows: {
    regular:
      '0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.06);',
    medium:
      '0px 0px 1px rgba(0, 0, 0, 0.12), 0px 3px 4px -1px rgba(0, 0, 0, 0.04);',
    large:
      '0px 0px 1px rgba(0, 0, 0, 0.12), 0px 6px 12px -2px rgba(0, 0, 0, 0.04);',
    'extra-large':
      '0px 0px 1px rgba(0, 0, 0, 0.12), 0px 20px 40px -8px rgba(0, 0, 0, 0.06);',
    huge: '0px 24px 32px rgba(7, 8, 23, 0.16);',
  },

  space: {
    sm: '8px',
    md: '16px',
    // new DS
    zero: '0px',
    tiny: '0.125rem',
    smallest: '0.25rem',
    smaller: '0.5rem',
    small: '0.75rem',
    regular: '1rem',
    medium: '1.5rem',
    large: '2rem',
    larger: '2.5rem',
    'extra-large': '3rem',
    big: '3.5rem',
    bigger: '4rem',
    'extra-big': '6rem',
    huge: '8rem',
    huger: '12rem',
    'extra-huge': '14rem',
    hugest: '16rem',
    colossal: '20rem',
  },

  radii: {
    none: '0',
    small: '0.125rem',
    regular: '0.25rem',
    medium: '0.375rem',
    large: '0.5rem',
    larger: '0.625rem',
    'extra-large': '0.75rem',
    huge: '1rem',
    hugest: '1.25m',
    full: '50%',
  },

  zIndices: {
    hide: -1,
    auto: 'auto',
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 2000,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800,
  },

  spacing: 8,

  styles: {
    global: {
      body: {
        bg: 'white',
        color: 'blue.900',
      },

      label: {
        fontSize: 'sm',
      },
    },
  },

  components: {
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: 'none !important',
        },
      },
    },

    AccordionButton: {
      baseStyle: {
        _focus: {
          boxShadow: 'none !important',
        },
      },
    },

    FormLabel: {
      variants: {
        floating: {
          top: 2,
          left: 0,
          zIndex: 2,
          position: 'absolute',
          backgroundColor: 'none',
          pointerEvents: 'none',
          mx: 3,
          transformOrigin: 'left top',
          fontSize: 'medium',
          _disabled: {
            opacity: 1,
          },
          ...activeLabelStyles,
        },
      },
    },

    Switch: {
      variants: {
        boxy: {
          track: {
            borderRadius: 'larger',
          },
          thumb: {
            borderRadius: 'larger',
          },
        },
      },
    },

    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown):not([type="checkbox"]) ~ label, textarea:not(:placeholder-shown) ~ label, .chakra-select__wrapper + label, .active':
              {
                ...activeLabelStyles,
              },
            'input[type="checkbox"] ~ label': {
              top: 'auto',
              left: 'auto',
              position: 'relative',
              zIndex: 'auto',
              backgroundColor: 'initial',
              pointerEvents: 'auto',
              mx: 'initial',
              px: 'initial',
              transformOrigin: 'initial',
              color: 'initial',
              fontSize: 'initial',
            },
            label: {
              top: 2,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'none',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              transformOrigin: 'left top',
              color: 'neutral.500',
              fontSize: 'medium',
              _disabled: {
                opacity: 1,
              },
            },
          },
        },
      },
    },
  },
});

export const styledTheme = {
  ...theme,
  colors: {
    ...theme.colors,
  },
  fonts: {
    ...theme.fonts,
  },
  spacing: (value: number) => {
    return value * 8;
  },
};
