/* eslint-disable import/prefer-default-export */

export const constants = {
  doNotLoadContent:
    'Não foi possível buscar {{content}}. Tente novamente mais tarde.',
  errorToLoadContent:
    'Ocorreu um erro inesperado ao buscar {{content}}. Tente novamente mais tarde.',
  errorToSubmitContent:
    'Ocorreu um erro inesperado. Tente novamente mais tarde.',
  updatedWithSuccess: '{{content}} alterado com sucesso!',
  publishedWithSuccess: '{{content}} publicado com sucesso!',
};
