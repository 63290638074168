import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import GenericForm from '../../../components/base/v2/genericForm';
import FlowUpsertForm from '../../../sections/flow/form';
import useBlocks from '../../../hooks/api/blocks';
import { emptyFlowObject } from '../../../providers/rules_flow/utils';
import { handleFormDataToModel } from '../../rulesFlow/utils';

import * as I from '../../../interfaces/blocks';
import * as I2 from '../../../interfaces/flow';
import { RetentionModel } from '../../../interfaces/retentionFlow';
import { formatConstants } from '../../../utils/formatConstants';
import { constants } from '../../../constants';

const BlocksCreate = () => {
  const { createBlock } = useBlocks();
  const { push } = useHistory();

  const handleSubmit = async (
    data: I.BlocksModel | I2.FlowModel | RetentionModel
  ) => {
    try {
      const res = await createBlock({
        data: handleFormDataToModel(data as I.BlocksModel),
      });
      if ([200, 201, 204].includes(res.request.status)) {
        const message = formatConstants(
          constants.publishedWithSuccess,
          'Bloco'
        );
        toast.success(message);
        push(`/blocks/info/${res.data.id}`);
        return;
      }
      const { reason } = JSON.parse(res.request.response);
      const message = formatConstants(constants.errorToSubmitContent, '');
      toast.error(`${message}: ${reason}`);
    } catch {
      const message = formatConstants(constants.errorToSubmitContent, '');
      toast.error(message);
    }
  };

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Blocos', href: '/blocks' },
        { label: 'Cadastro' },
      ]}
      title="Cadastro de Blocos"
      backButtonUrl="/blocks"
      showBackButton
    >
      <FlowUpsertForm
        flow={emptyFlowObject}
        handleSubmit={handleSubmit}
        isLoaded
        isEditing
        isBlock
      />
    </GenericForm>
  );
};

export default BlocksCreate;
