import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import GenericForm from '../../../components/base/v2/genericForm';
import useRulesFlows from '../../../hooks/api/rules_flows';
import FlowUpsertForm from '../../../sections/flow/form';
import { useRulesFlow } from '../../../providers/rules_flow';
import { handleFormDataToModel } from '../utils';
import { emptyFlowObject } from '../../../providers/rules_flow/utils';

import * as I from '../../../interfaces/flow';
import { BlocksModel } from '../../../interfaces/blocks';
import { RetentionModel } from '../../../interfaces/retentionFlow';
import { formatConstants } from '../../../utils/formatConstants';
import { constants } from '../../../constants';

const RulesFlowCreate = () => {
  const { isLoaded } = useRulesFlow();
  const { createFlow } = useRulesFlows();
  const history = useHistory();

  const handleSubmit = async (
    data: I.FlowModel | BlocksModel | RetentionModel
  ) => {
    try {
      const res = await createFlow({
        data: handleFormDataToModel(data as I.FlowModel),
      });
      if ([200, 201, 204].includes(res.request.status)) {
        const message = formatConstants(
          constants.publishedWithSuccess,
          'Fluxo'
        );
        toast.success(message);
        history.push(`/rules-flow/info/${res.data.id}`);
        return;
      }
      const { reason } = JSON.parse(res.request.response);
      const message = formatConstants(constants.errorToSubmitContent, '');
      toast.error(`${message}: ${reason}`);
    } catch {
      const message = formatConstants(constants.errorToSubmitContent, '');
      toast.error(message);
    }
  };

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Fluxo de Regras', href: '/rules-flow' },
        { label: 'Cadastro' },
      ]}
      title="Cadastro de Fluxo de Regras"
      backButtonUrl="/rules-flow"
      showBackButton
    >
      <FlowUpsertForm
        flow={emptyFlowObject}
        isLoaded={isLoaded}
        handleSubmit={handleSubmit}
        isEditing
      />
    </GenericForm>
  );
};

export default RulesFlowCreate;
