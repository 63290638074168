import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import GenericForm from '../../../components/base/v2/genericForm';
import FlowUpsertForm from '../../../sections/flow/form';
import { useRetentionFlow } from '../../../hooks/api/retentionFlow';
import { emptyRetentionFlow, handleFormDataToRetentionModel } from '../utils';

import { RetentionModel } from '../../../interfaces/retentionFlow';
import { BlocksModel } from '../../../interfaces/blocks';
import { FlowModel } from '../../../interfaces/flow';
import { formatConstants } from '../../../utils/formatConstants';
import { constants } from '../../../constants';

const RetentionFlowInfo = () => {
  const [initialValues, setInitialValues] =
    useState<RetentionModel>(emptyRetentionFlow);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { updateRetentionFlow, getRetentionFlowById } = useRetentionFlow();
  const { id } = useParams<{ id: string }>();

  const handleEditing = () => {
    setIsEditing(!isEditing);
  };
  const loadInfo = async () => {
    try {
      setIsLoading(true);
      const { data } = await getRetentionFlowById(id);
      setInitialValues(data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (
    data: FlowModel | BlocksModel | RetentionModel
  ) => {
    try {
      const res = await updateRetentionFlow({
        data: handleFormDataToRetentionModel(data as RetentionModel),
        id,
        version: initialValues.version,
      });
      if ([200, 201, 204].includes(res.request.status)) {
        handleEditing();
        const message = formatConstants(
          constants.updatedWithSuccess,
          'Fluxo de retenção'
        );
        toast.success(message);
        loadInfo();
        return;
      }

      const { reason } = JSON.parse(res.request.response);
      const message = formatConstants(constants.errorToSubmitContent, '');
      toast.error(`${message}: ${reason}`);
    } catch {
      const message = formatConstants(constants.errorToSubmitContent, '');
      toast.error(message);
    }
  };

  useEffect(() => {
    loadInfo();
  }, []);

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Fluxo de Retenção', href: '/retention-flow' },
        { label: initialValues?.description },
      ]}
      title={initialValues?.description}
      isDisabled={!isEditing}
      handleAction={handleEditing}
      actionText="Editar Fluxo"
      backButtonUrl="/retention-flow"
      showBackButton
    >
      <FlowUpsertForm
        flow={initialValues}
        isLoaded={!isLoading}
        handleSubmit={handleSubmit}
        isEditing={isEditing}
        handleEditing={handleEditing}
        isRetention
      />
    </GenericForm>
  );
};

export default RetentionFlowInfo;
