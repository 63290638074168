import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import GenericForm from '../../../components/base/v2/genericForm';
import FlowUpsertForm from '../../../sections/flow/form';
import { emptyRetentionFlow, handleFormDataToRetentionModel } from '../utils';
import { useRetentionFlow } from '../../../hooks/api/retentionFlow';
import { RetentionModel } from '../../../interfaces/retentionFlow';
import { FlowModel } from '../../../interfaces/flow';
import { BlocksModel } from '../../../interfaces/blocks';
import { formatConstants } from '../../../utils/formatConstants';
import { constants } from '../../../constants';

const RetentionFlowCreate = () => {
  const { push } = useHistory();
  const { createRetentionFlow } = useRetentionFlow();

  const handleSubmit = async (
    data: FlowModel | BlocksModel | RetentionModel
  ) => {
    try {
      const res = await createRetentionFlow({
        data: handleFormDataToRetentionModel(data as RetentionModel),
      });
      if ([200, 201, 204].includes(res.request.status)) {
        const message = formatConstants(
          constants.publishedWithSuccess,
          'Fluxo de retenção'
        );
        toast.success(message);
        push(`/retention-flow/info/${res.data.id}`);
        return;
      }

      const { reason } = JSON.parse(res.request.response);
      const message = formatConstants(constants.errorToSubmitContent, '');
      toast.error(`${message}: ${reason}`);
    } catch {
      const message = formatConstants(constants.errorToSubmitContent, '');
      toast.error(message);
    }
  };

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Fluxo de retenção', href: '/retention-flow' },
        { label: 'Cadastro' },
      ]}
      title="Cadastro de Fluxo de retenção"
      backButtonUrl="/retention-flow"
      showBackButton
    >
      <FlowUpsertForm
        flow={emptyRetentionFlow}
        handleSubmit={handleSubmit}
        isLoaded
        isEditing
        isRetention
      />
    </GenericForm>
  );
};

export default RetentionFlowCreate;
