import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AbsoluteCenter,
  useDisclosure,
  MenuItem,
  Icon,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FaRegClone } from 'react-icons/fa';
import { useRulesModels } from '../../../providers/models';
import useModels from '../../../hooks/api/models';
import LoadingSpinner from '../../../components/loadingSpinner';
import GenericForm from '../../../components/base/v2/genericForm';
import ModelsUpsertForm from '../form';

import * as I from '../interfaces';

const Body = (): React.ReactElement => {
  const { push } = useHistory();
  const { updateModels, createModel } = useModels();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    dataContext,
    isLoaded,
    hasID,
    handleDisabled,
    isDisabled,
    getNewVersion,
  } = useRulesModels();

  useEffect(() => {
    if (hasID && isLoaded) {
      handleDisabled();
    }
  }, [hasID, isLoaded]);

  const onUpdate = async ({ data, id, version }: I.SubmitFunctionProp) => {
    await updateModels({
      id,
      data,
      version,
    })
      .then((res) => {
        if ([200, 201, 204].includes(res.request.status)) {
          toast.success('Modelo alterado com sucesso!');
          getNewVersion();
          return;
        }
        const { reason } = JSON.parse(res.request.response);
        toast.error(`Não foi possível alterar o Modelo: ${reason}`);
      })
      .catch(() => {
        toast.error(
          'Não foi possível alterar o modelo. Tente novamente mais tarde.'
        );
      });
  };

  const onCreate = async ({ data }: I.SubmitFunctionProp) => {
    await createModel(data)
      .then((res) => {
        if (res) {
          if ([200, 201, 204].includes(res.request.status)) {
            toast.success('Modelo criado com sucesso!');
            push(`/models/info?id=${res.data.id}`);
            return;
          }
          const { reason } = JSON.parse(res.request.response);
          toast.error(`Não foi possível criar o Modelo: ${reason}`);
        }
      })
      .catch(() => {
        toast.error(
          'Não foi possível criar o Modelo. Tente novamente mais tarde.'
        );
      });
  };

  if (!isLoaded) {
    return (
      <AbsoluteCenter>
        <LoadingSpinner />
      </AbsoluteCenter>
    );
  }

  const handleDirectToList = () => {
    push(`/models/info/clients/${dataContext.id}`);
  };

  const MenuItemButton = () => (
    <MenuItem icon={<Icon as={FaRegClone} />} onClick={onOpen}>
      Clonar Modelo
    </MenuItem>
  );

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Modelos', href: '/models' },
        { label: dataContext.description || 'Cadastro' },
      ]}
      showBackButton
      title={dataContext.description || 'Cadastro de Modelo'}
      handleAction={handleDisabled}
      backButtonUrl="/models"
      actionText="Editar Modelo"
      isDisabled={isDisabled}
      {...(hasID && {
        secondaryActions: [
          {
            text: 'Clientes',
            action: handleDirectToList,
          },
        ],
      })}
      {...(hasID && { menuItem: <MenuItemButton /> })}
    >
      <ModelsUpsertForm
        isOpenModal={isOpen}
        onCloseModal={onClose}
        onSubmit={hasID ? onUpdate : onCreate}
      />
    </GenericForm>
  );
};

export default Body;
