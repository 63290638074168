import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Icon, Stack, Wrap, WrapItem } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FaRegClone } from 'react-icons/fa';
import { HiOutlineTrash } from 'react-icons/hi';
import InputSearchSelect from '../../../inputs/searchselect';
import InputSelect from '../../../inputs/select';
import Button from '../../../button';
import Information from './termsInformation';
import ParamsInformation from './paramsInformation';
import InputFactory from '../../../inputs/factory';
import TooltipWrapper from '../../../../tooltip/tooltip_wrapper';
import { useRulesFlow } from '../../../../../../providers/rules_flow';
import { useRetentionFlow } from '../../../../../../hooks/api/retentionFlow';
import { constants } from '../../../../../../constants';
import clone from '../../../../../../utils/clone';
import handleType from '../../../../../../utils/handleType';
import { handleRulesData } from '../../../../../../pages/retentionFlow/utils';
import { formatConstants } from '../../../../../../utils/formatConstants';
import { flowPolicyError, retentionPolicyError } from '../utils';
import { emptyRulesDataObject } from '../../../../../../providers/rules_flow/utils';

import * as I from '../interfaces';
import { RulesDataModel } from '../../../../../../providers/rules_flow/interfaces';
import LoadingSpinner from '../../../../../loadingSpinner';

const TermsBase = ({
  term,
  terms,
  termIndex,
  nodeIndex,
  appendTerms,
  removeTerms,
  isEditing,
  isRetention,
}: I.TermsProps): React.ReactElement => {
  const { rulesData: rulesFlowData } = useRulesFlow();
  const [rulesData, setRulesData] =
    useState<RulesDataModel>(emptyRulesDataObject);

  const { getRetentionFlowRules } = useRetentionFlow();
  const methods = useFormContext();

  const {
    register,
    getValues,
    formState: { errors },
    setValue,
    control,
  } = methods;

  const ruleName = useWatch({
    control,
    name: `nodes.${nodeIndex}.terms.${termIndex}.rule.name`,
  });

  const handleCloneTerms = (): void => {
    const termToClone = clone(
      getValues(`nodes.${nodeIndex}.terms.${termIndex}`)
    );
    appendTerms(termToClone);
    setValue(`nodes.${nodeIndex}.terms.${terms.length}`, termToClone);
  };

  const handleRemoveTerms = (): void => {
    removeTerms(termIndex);
  };

  const loadRules = async () => {
    try {
      const res = await getRetentionFlowRules();
      if ([200, 201, 204].includes(res.request.status)) {
        const data = handleRulesData(res.data);
        setRulesData(data);
        return;
      }

      const { reason } = JSON.parse(res.request.response);
      const message = formatConstants(constants.doNotLoadContent, 'as regras');
      toast.error(`${message}: ${reason}`);
    } catch {
      const message = formatConstants(
        constants.errorToLoadContent,
        'as regras'
      );
      toast.error(message);
    }
  };

  useEffect(() => {
    if (isRetention) {
      loadRules();
    } else {
      setRulesData(rulesFlowData);
    }
  }, [rulesData]);

  const hasOnlyTerm = terms.length <= 1;

  if (!rulesData || rulesData === emptyRulesDataObject) {
    return <LoadingSpinner />;
  }

  return (
    <Stack padding="medium" spacing="medium">
      <Stack direction="row" spacing="regular">
        {isEditing ? (
          <Wrap spacing="regular">
            <WrapItem>
              <InputSearchSelect
                control={control}
                label="Regra"
                name={`nodes.${nodeIndex}.terms.${termIndex}.rule.name`}
                formcontrol={{
                  isRequired: true,
                  error:
                    errors.nodes?.[nodeIndex]?.terms?.[termIndex]?.rule?.name,
                }}
                initialOptions={rulesData?.rulesNames}
                isClearable={false}
              />
            </WrapItem>

            <WrapItem>
              <InputSelect
                label="Complemento"
                options={[
                  { option: 'Sim', value: 'true' },
                  { option: 'Não', value: 'false' },
                ]}
                {...register(
                  `nodes.${nodeIndex}.terms.${termIndex}.complement`
                )}
                formcontrol={{
                  isRequired: true,
                  error:
                    errors.nodes?.[nodeIndex]?.terms?.[termIndex]?.complement,
                }}
              />
            </WrapItem>

            <WrapItem>
              <InputSelect
                label="Política de erro"
                options={isRetention ? retentionPolicyError : flowPolicyError}
                {...register(
                  `nodes.${nodeIndex}.terms.${termIndex}.error_policy`
                )}
                formcontrol={{
                  isRequired: true,
                  error:
                    errors.nodes?.[nodeIndex]?.terms?.[termIndex]?.error_policy,
                }}
              />
            </WrapItem>
          </Wrap>
        ) : (
          <Information
            nodeIndex={nodeIndex}
            term={term}
            termIndex={termIndex}
          />
        )}

        <Stack direction="row" marginLeft="auto !important">
          <Button onClick={handleCloneTerms} isDisabled={!isEditing}>
            <Icon as={FaRegClone} />
          </Button>
          <Button onClick={handleRemoveTerms} isDisabled={hasOnlyTerm}>
            <Icon as={HiOutlineTrash} />
          </Button>
        </Stack>
      </Stack>

      {isEditing ? (
        <Wrap
          spacing="regular"
          borderBottom="1px solid"
          borderColor="neutral.400"
          paddingBottom="medium"
        >
          {rulesData?.rules
            ?.find((item) => item.name === ruleName)
            ?.parameters?.map((item, index) => {
              const key = `nodes[${nodeIndex}].terms[${termIndex}].rule.parameters[${item.name}]-${index}`;

              return (
                <WrapItem key={key}>
                  <TooltipWrapper
                    label={item.name}
                    description={item.description}
                  >
                    {InputFactory({
                      name: item.name,
                      path: `nodes.${nodeIndex}.terms.${termIndex}.rule.parameters.${item.name}`,
                      type: handleType(item),
                      defaultValue: item.default,
                      isRequired: item.mandatory,
                      multiple: item.type === 'array of strings',
                      description: item.description,
                      values: item.one_of,
                      isDisabled: false,
                      methods,
                    })}
                  </TooltipWrapper>
                </WrapItem>
              );
            })}
        </Wrap>
      ) : (
        <ParamsInformation
          nodeIndex={nodeIndex}
          term={term}
          termIndex={termIndex}
          rules={rulesData?.rules}
        />
      )}
    </Stack>
  );
};

export default TermsBase;
